import styles from 'components/Cards/CardValueProp/CardValueProp.module.scss'
import { CardMedia as AscendCardMedia, Box } from '@achieve/ascend'
import { MediaImageStandard, Typography } from 'components/Contentful'
import classNames from 'classnames'

function CardValueProp({
  content,
  breakline,
  dividerOnMobile = false,
  pos = 0,
  cardStyle,
  className,
  gridConfig,
}) {
  const { cardTitleText, cardText, cardImage, cardJson } = content || {}
  const titleVariant = gridConfig?.titleVariant ?? 'headingMd'
  const titleVariantMobile = gridConfig?.titleVariantMobile ?? 'bodyLg'
  const subTitleVariant = gridConfig?.subTitleVariant ?? 'bodySm'
  const subTitleVariantMobile = gridConfig?.subTitleVariantMobile ?? 'bodySm'
  return (
    <Box
      variant="none"
      data-testid="card-container"
      className={classNames([
        cardStyle
          ? styles[`card-${cardStyle}`]
          : dividerOnMobile
          ? styles['card-divider']
          : styles['card'],
        breakline && styles['breakline'],
        dividerOnMobile && pos !== 0 && styles['card-divider-border'],
        className,
      ])}
    >
      <Box className={styles['card-img']}>
        {cardImage && (
          <AscendCardMedia data-testid="ascend-card-media">
            <MediaImageStandard
              data-testid="card-media-image"
              layout="fixed"
              width={cardJson?.imagesize ?? 60}
              height={cardJson?.imagesize ?? 60}
              content={cardImage}
            />
          </AscendCardMedia>
        )}
      </Box>
      <Box className={styles['card-info']} data-testid="card-value-prop-card-info">
        {cardTitleText && (
          <Typography
            data-testid="card-title"
            className={classNames(
              styles['card-text-title'],
              `${titleVariantMobile} lg:${titleVariant} font-medium`
            )}
            variant="normal"
            content={cardTitleText}
          />
        )}

        {cardText && (
          <Typography
            data-testid="card-subtitle"
            className={classNames(
              styles['card-text-subtitle'],
              `${subTitleVariantMobile} lg:${subTitleVariant} font-regular`
            )}
            component="p"
            content={cardText}
          />
        )}
      </Box>
    </Box>
  )
}

export { CardValueProp }
