import { Typography } from 'components/Contentful'
import { Grid } from '@achieve/ascend'
import styles from './BlockQuote.module.scss'

import React from 'react'
import classNames from 'classnames'

const BlockQuote = ({ text }) => {
  return (
    <Grid className={styles['container-grid']}>
      {text &&
        text.map((textContent, idx) => (
          <React.Fragment key={idx}>
            <Typography
              className={classNames(styles['text'], 'font-light md:font-medium')}
              variant={'headingMd'}
              content={textContent.content[0].value}
            />
          </React.Fragment>
        ))}
    </Grid>
  )
}

export { BlockQuote }
