import styles from './CardPhone.module.scss'
import { Box } from '@achieve/ascend'
import { Typography } from 'components/Contentful'
import classNames from 'classnames'

function CardPhone({ content, breakline }) {
  const { cardTitleText, cardText } = content || {}

  return (
    <Box
      variant="none"
      data-testid="card-container"
      className={[styles['card'], breakline && styles['breakline']]}
    >
      <Box className={styles['card-info']}>
        {cardTitleText && (
          <Typography
            data-testid="card-title"
            variant={'headingMd'}
            fontWeight="medium"
            className={styles['card-text-title']}
            content={cardTitleText}
          />
        )}

        {cardText && (
          <Typography
            data-testid="card-subtitle"
            className={classNames(
              styles['card-text-subtitle'],
              'headingLg lg:headingMd font-light lg:font-medium'
            )}
            component="span"
            content={cardText}
          />
        )}
      </Box>
    </Box>
  )
}

export { CardPhone }
